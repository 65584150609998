/************
vars
*************/

$red: #A1171B;
$yellow: #d27204;
$white: #e9e5e4;
$black: #0D0604;
$orange: #B3450B;
$maroon: #5F150E;

$max-width: 1300px;

/************
fonts
*************/

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,600;1,600&family=Montserrat:wght@300;500;700;900&display=swap');

$heading-font: 'Barlow Condensed', sans-serif;
$body-font: 'Montserrat', sans-serif;