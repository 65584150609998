@use "./vars.scss" as v;
@use "./mixins.scss" as m;

.header-small {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    &:before {
        @include m.fullWidth();
        background: url('../img/bg.jpg') no-repeat center;
        background-size: cover;
        opacity: .7;
        z-index: -1;
    }
    &__img--logo {
        position: relative;
        z-index: 0;
        width: 100px;
        display: block;
        filter: invert(99%) sepia(1%) saturate(4349%) hue-rotate(189deg) brightness(118%) contrast(80%);
    }
    @include m.for-size(phone-only) {
        &:before {
            background-position: center center;
        }
    }
}