@use "./vars.scss" as v;

.footer {
    &__social-link-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin: 0;
        padding: 0;
    }
    &__social-link-list-item {
        list-style: none;
        .social-svg {
            &:hover {
                background: v.$white;
            }
        }
    }
}
