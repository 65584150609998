.main {
    &__link-list {
        margin: 0;
        padding: 60px 0 30px;
        text-align: center;
    }
    &__link-list-item {
        list-style: none;
        margin: 0 0 1rem;
        padding: 0;
    }
}